import { useInView } from 'react-intersection-observer';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import React from 'react';

import To from '../to';
import Logo from '../logo';
import Guide from '../guide';
import Portal from '../portal';

import { removeDuplicatesFromArray, subUrlConstruct } from '../../helpers';

import useSharedContext from '../../hooks/useSharedContext';

import './index.scss';

const Header = () => {
    const {
        navigationLinks,
        navigationOpened,
        setNavigationOpened,
    } = useSharedContext();

    const {
        contentfulNavigation: {
            content
        }
    } = useStaticQuery(graphql`
        query {
            contentfulNavigation {
                content {
                    ... on ContentfulLink {
                        id
                        url
                        title
                    }
                    ... on ContentfulPage {
                        id
                        slug
                        title
                    }
                }
            }
        }`);

    const { ref, inView } = useInView({ threshold: 0, triggerOnce: false });

    return (
        <>
            <header>
                <ul className='header-content'>
                    <li>
                        <Logo />
                    </li>
                    <li>
                        <button
                            aria-label='Toggle navigation menu'
                            onClick={() => setNavigationOpened(val => !val)}
                            className={classNames(
                                'navigation-toggle',
                                {
                                    'active': navigationOpened,
                                }
                            )}
                        />
                    </li>
                </ul>
            </header>
            <Portal>
                <section
                    className={classNames(
                        'navigation-holder',
                        {
                            'active': navigationOpened,
                            'animate': inView,
                        }
                    )}
                >
                    <Guide>
                        <nav ref={ref}>
                            {removeDuplicatesFromArray([...navigationLinks, ...content].map(item => ({
                                id: item.url || item.id,
                                slug: item.url || item.slug,
                                title: item.title,
                            }))).map(link => (
                                <To
                                    key={link.id}
                                    url={subUrlConstruct(link.slug)}
                                    onClick={() => setNavigationOpened(false)}
                                >
                                    {link.title}
                                </To>
                            ))}
                        </nav>
                    </Guide>
                </section>
            </Portal>
        </>
    );
};

export default Header;
