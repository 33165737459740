import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = props => {
    const {
        children,
    } = props;

    const ref = useRef(null);

    useEffect(() => {
        ref.current = document.body;
    }, []);

    return ref.current && createPortal(children, ref.current);
};

Portal.propTypes = {
    children: PropTypes.any,
};

export default Portal;
