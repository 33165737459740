import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import './index.scss';

const Footer = () => {
    const {
        contentfulLayout: {
            footerCopy,
        }
    } = useStaticQuery(graphql`
        query {
            contentfulLayout {
                footerCopy
            }
        }`);

    return (
        <footer>
            <p>
                {`${new Date().getFullYear()} © ${footerCopy}`}
            </p>
        </footer>
    );
};

export default Footer;
