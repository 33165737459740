import { conditionalSpread } from 'clyne-core';

export const urlConstruct = props => `${props.pathname}${props.search ? `?${props.search.replace('?', '')}` : ''}${props.hash || ''}`;

export const toURLFormat = str => `${str}`.replaceAll(' ', '').toLowerCase();

export const subUrlConstruct = (slug, subName) => urlConstruct({
    pathname: `/${slug}`,
    ...conditionalSpread({
        search: toURLFormat(subName),
    }, subName),
});

export const isBrowser = () => typeof window !== 'undefined';

export const lerp = (a, b, n) => (1 - n) * a + n * b;

export const checkDirection = (key, directionToGo) => directionToGo === key || directionToGo === `${key}Was`;

export const removeDuplicatesFromArray = arr => {
    const set = new Set(arr.map(val => JSON.stringify(val)));

    return Array.from(set).map(val => JSON.parse(val));
};

export const checkFPS = () => new Promise(resolve => {
    const times = [];
    let fps = 0;
    let count = 0;

    if (isBrowser()) {
        function refreshLoop() {
            count++;
            window.requestAnimationFrame(() => {
                const now = performance.now();
                while (times.length > 0 && times[0] <= now - 500) {
                    times.shift();
                }
                times.push(now);
                fps += times.length;
                count < 500 ? setTimeout(() => {
                    refreshLoop();
                }, 0) : resolve(fps / count);
            });
        }

        refreshLoop();
    }
});
