import React from 'react';

import Aside from '../aside';
import Footer from '../footer';
import Header from '../header';

import './index.scss';

const Frame = () => {
    return (
        <div className='frame-holder'>
            <Header />
            <Footer />
            <Aside />
        </div>
    );
};

export default Frame;
