import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { graphql, useStaticQuery } from 'gatsby';
import { conditionalSpread } from 'clyne-core';
import PropTypes from 'prop-types';

import Blurs from '../components/blurs';
import Frame from '../components/frame';
import Cursor from '../components/cursor';
import MorphingShape from '../components/morphingShape';
import PageTransition from '../components/pageTransition';

import { checkFPS } from '../helpers';

import config from '../config';

import SharedContext from '../contexts/sharedContext';

import '../assets/scss/main.scss';

const loginRoute = {
    id: 'login',
    slug: 'login',
    title: 'Login',
};

const Layout = props => {
    const {
        children,
        location,
    } = props;

    const {
        contentfulLayout: {
            leftNavigation,
            rightNavigation,
        }
    } = useStaticQuery(graphql`
        query {
            contentfulLayout {
                leftNavigation {
                    id
                    slug
                    title
                }
                rightNavigation {
                    id
                    slug
                    title
                }
            }
        }`);

    const cursorRef = useRef(null);
    const leftAsideRef = useRef(null);
    const logoCircleRef = useRef(null);
    const rightAsideRef = useRef(null);
    const [directionToGo, setDirectionToGo] = useState('');
    const [scrollAxis, setScrollAxis] = useState('vertical');
    const [morphingShape, setMorphingShape] = useState(null);
    const [scrollContainer, setScrollContainer] = useState(null);
    const [initialAnimated, setInitialAnimated] = useState(false);
    const [navigationOpened, setNavigationOpened] = useState(false);
    const [reTriggerMorphingShape, setReTriggerMorphingShape] = useState(0);

    const navigationLinks = useMemo(() => [
        ...[
            ...leftNavigation,
            ...conditionalSpread([loginRoute], config.features.login.enabled),
        ]?.map(link => ({ ...link, direction: 'left' })),
        ...rightNavigation?.map(link => ({ ...link, direction: 'right' })),
    ], [leftNavigation, rightNavigation]);

    useEffect(() => {
        checkFPS().then(fps => {
            document.documentElement.classList[fps < 40 ? 'add' : 'remove']('disable-animations');
        });
    }, [location.pathname]);

    const contextValues = {
        cursorRef,
        loginRoute,
        scrollAxis,
        leftAsideRef,
        rightAsideRef,
        logoCircleRef,
        morphingShape,
        directionToGo,
        setScrollAxis,
        leftNavigation,
        rightNavigation,
        scrollContainer,
        initialAnimated,
        navigationLinks,
        setMorphingShape,
        setDirectionToGo,
        navigationOpened,
        setScrollContainer,
        setInitialAnimated,
        setNavigationOpened,
        reTriggerMorphingShape,
        setReTriggerMorphingShape: () => setReTriggerMorphingShape(val => val + 1),
    };

    return (
        <ParallaxProvider
            scrollAxis={scrollAxis}
            scrollContainer={scrollContainer}
        >
            <SharedContext.Provider value={contextValues}>
                <Cursor />
                <Frame />
                <PageTransition location={location}>
                    {children}
                </PageTransition>
                <MorphingShape />
                <Blurs />
            </SharedContext.Provider>
        </ParallaxProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.any,
    location: PropTypes.object,
};

export default Layout;
