import React, { useEffect, useRef } from 'react';

import CursorClass from '../../classes/cursorClass';
import useSharedContext from '../../hooks/useSharedContext';

import './index.scss';

const Cursor = () => {
    const ref = useRef(null);

    const { cursorRef } = useSharedContext();

    useEffect(() => {
        !!ref.current && (cursorRef.current = new CursorClass(ref.current));
    }, [ref.current]); // eslint-disable-line

    return (
        <svg
            ref={ref}
            width={280}
            height={280}
            viewBox='0 0 280 280'
            className='cursor'
            style={{ opacity: 0 }}
        >
            <defs>
                <filter
                    x='-50%'
                    y='-50%'
                    width='200%'
                    height='200%'
                    id='cursorFilter'
                    filterUnits='objectBoundingBox'
                >
                    <feTurbulence
                        result='warp'
                        numOctaves={3}
                        type='fractalNoise'
                        baseFrequency='0.0 0.0'
                    />
                    <feDisplacementMap
                        scale={0}
                        in2='warp'
                        in='SourceGraphic'
                        xChannelSelector='R'
                        yChannelSelector='G'
                    />
                </filter>
            </defs>
            <circle
                r={20}
                cx={140}
                cy={140}
                className='cursor__inner'
            />
        </svg>
    );
};

export default Cursor;
