import { fakeArray } from 'clyne-core';
import classNames from 'classnames';
import React from 'react';

import './index.scss';

const Blurs = () => {
    return (
        <div className='blurs-holder'>
            {fakeArray(3).map((_, index) => (
                <div
                    key={index}
                    className={classNames(
                        'blur-shape',
                        `blur-${index + 1}`
                    )}
                >
                    <div className='blur' />
                </div>
            ))}
        </div>
    );
};

export default Blurs;
