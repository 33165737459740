import { useInView } from 'react-intersection-observer';
import React, { useEffect, useState } from 'react';
import { conditionalSpread } from 'clyne-core';
import { useLocation } from '@reach/router';
import classNames from 'classnames';

import useSharedContext from '../../hooks/useSharedContext';

import './index.scss';

const MorphingShape = () => {
    const {
        morphingShape,
        setReTriggerMorphingShape,
    } = useSharedContext();

    const location = useLocation();

    const [done, setDone] = useState(false);

    useEffect(() => {
        const handler = () => setReTriggerMorphingShape();

        window.addEventListener('resize', handler);

        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []); // eslint-disable-line

    const { ref, inView } = useInView({ threshold: 0, triggerOnce: false });

    useEffect(() => {
        setDone(false);
    }, [morphingShape]);

    const sourceStyle = morphingShape?.getBoundingClientRect();

    const width = morphingShape?.clientWidth;
    const height = morphingShape?.clientHeight;
    const x = sourceStyle?.x + width / 2;
    const y = sourceStyle?.y + height / 2;

    return (
        <div
            style={{
                transform: `translate3d(${x}px, ${y}px, 0)`,
            }}
            className={classNames(
                'morphing-shape',
                {
                    'done': done && location.pathname !== '/',
                }
            )}
            onTransitionEnd={() => {
                if (inView) {
                    morphingShape.classList.add('morphed');
                    setDone(true);
                }
            }}
        >
            <div
                ref={ref}
                className='shape'
                style={{
                    width,
                    height,
                    ...conditionalSpread({
                        borderRadius: 0,
                    }, morphingShape?.id !== 'logo-circle' && width && height),
                }}
            />
        </div>
    );
};

export default MorphingShape;
