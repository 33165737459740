import { useEffect, useState } from 'react';

import { isBrowser } from '../helpers';

const useDevice = () => {
    const [isMobile, setIsMobile] = useState(isBrowser() ? document.documentElement.clientWidth < 577 : false);

    function checkForDevice() {
        setIsMobile(document.documentElement.clientWidth < 577);
    }

    useEffect(() => {
        checkForDevice();

        window.addEventListener('resize', checkForDevice);

        return () => {
            window.removeEventListener('resize', checkForDevice);
        };
    }, []);

    return { isMobile };
};

export default useDevice;
