import React, { useEffect, useRef, useState } from 'react';
import { conditionalSpread } from 'clyne-core';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import To from '../to';

import config from '../../config';

import { subUrlConstruct } from '../../helpers';

import useSharedContext from '../../hooks/useSharedContext';

import './index.scss';

const Aside = () => {
    const location = useLocation();

    const ref = useRef(null);

    const [route, setRoute] = useState(null);

    const {
        loginRoute,
        leftAsideRef,
        directionToGo,
        rightAsideRef,
        logoCircleRef,
        leftNavigation,
        rightNavigation,
        navigationLinks,
        setMorphingShape,
        setDirectionToGo,
        navigationOpened,
        setReTriggerMorphingShape,
    } = useSharedContext();

    useEffect(() => {
        const route = navigationLinks?.find(link => location?.pathname?.includes(link?.slug));
        setRoute(route);
        setDirectionToGo(val => (route?.direction || (val ? `${val}Was` : '')));
    }, [location?.pathname, navigationLinks]); // eslint-disable-line

    const hasDirection = directionToGo === 'right' || directionToGo === 'left';

    useEffect(() => {
        if (hasDirection && route) {
            const element = ref.current.querySelector(`[href*="${subUrlConstruct(route.slug)}"]`);
            !!element && setMorphingShape(element);
        } else if (!config.morphingShape.excludedRoutes.some(route => location.pathname === route || location.pathname === `${route}/`)) {
            logoCircleRef.current && setMorphingShape(logoCircleRef.current);
        }
    }, [hasDirection, route]); // eslint-disable-line

    const linkRenderer = (links, direction) => links.map(link => (
        <To
            key={link.id}
            url={subUrlConstruct(link.slug)}
            onClick={e => {
                if (e.current.target.classList.contains('active')) {
                    navigate('/');
                } else {
                    setDirectionToGo(direction);
                    setMorphingShape(e.current.target);
                }
            }}
        >
            {link.title}
        </To>
    ));

    return (
        <div
            ref={ref}
            className={classNames(
                'aside-holder',
                {
                    'hidden': navigationOpened,
                    [`dtg-${directionToGo} active`]: hasDirection,
                }
            )}
            onTransitionEnd={() => setReTriggerMorphingShape()}
        >
            <aside ref={leftAsideRef}>
                {linkRenderer([
                    ...leftNavigation,
                    ...conditionalSpread([loginRoute], config.features.login.enabled),
                ], 'left')}
            </aside>
            <aside ref={rightAsideRef}>
                {linkRenderer(rightNavigation, 'right')}
            </aside>
        </div>
    );
};

export default Aside;
