import PropTypes from 'prop-types';
import React from 'react';

import './index.scss';

const Guide = props => {
    const {
        children,
    } = props;

    return (
        <div className='guide'>
            {children}
        </div>
    );
};

Guide.propTypes = {
    children: PropTypes.any,
};

export default Guide;
