import { conditionalSpread, isExternalURL, isFunction, safeREL } from 'clyne-core';
import { toProps } from 'clyne-core/props';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const To = props => {
    const {
        url,
        target,
        onClick,
        children,
        className,
        ariaLabel,
    } = props;

    const ref = useRef(null);

    const sharedProps = {
        target,
        onClick,
        className,
        'aria-label': ariaLabel,
        ...conditionalSpread({
            rel: safeREL,
        }, target !== '_self'),
    };

    return isExternalURL(url) ? (
        <a
            href={url}
            {...sharedProps}
        >
            {children}
        </a>
    ) : (
        <Link
            {...sharedProps}
            to={url || '/'}
            ref={ref}
            activeClassName='active'
            onClick={e => isFunction(onClick, false) && onClick({
                ...e,
                current: {
                    target: ref.current,
                }
            })}
        >
            {children}
        </Link>
    );
};

To.defaultProps = {
    url: '/',
    target: toProps.target[0],
};

To.propTypes = {
    url: PropTypes.string,
    target: PropTypes.oneOf(toProps.target),
    onClick: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
};

export default To;
