const config = {
    features: {
        login: {
            enabled: true,
        },
    },
    morphingShape: {
        excludedRoutes: [
            '/',
            '/404',
            '/login',
        ],
    },
    hubSpot: {
        api: 'https://api.hsforms.com/submissions/v3/integration/submit/',
        portal: 26693573,
        form: '558bd90b-1bec-46b3-9564-1e3a4412bc3a',
    },
};

export default config;
