import { TransitionGroup, Transition } from 'react-transition-group';
import { useParallaxController } from 'react-scroll-parallax';
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useDevice from '../../hooks/useDevice';
import useSharedContext from '../../hooks/useSharedContext';

import { checkDirection } from '../../helpers';

import './index.scss';

const timeout = 1000;

const transition = `transform ${timeout}ms`;

const getTransitionStyles = isRight => ({
    entering: {
        transform: `translate3d(${isRight ? '100%' : '-100%'}, 0, 0)`,
    },
    entered: {
        transition,
        transform: `translate3d(0, 0, 0)`,
    },
    exiting: {
        transition,
        transform: `translate3d(${!isRight ? '100%' : '-100%'}, 0, 0)`,
    },
});

const PageTransition = props => {
    const {
        children,
        location,
    } = props;

    const parallaxController = useParallaxController();

    const {
        cursorRef,
        scrollAxis,
        directionToGo,
        scrollContainer,
        setScrollContainer,
        setInitialAnimated,
        setReTriggerMorphingShape,
    } = useSharedContext();

    const timerRef = useRef();

    const {
        isMobile,
    } = useDevice();

    useEffect(() => {
        parallaxController.update();

        if (!isMobile) {
            const buttons = 'a, button, .cursor-pointer';

            document.documentElement.querySelectorAll(buttons).forEach(element => {
                element.addEventListener('mouseenter', () => cursorRef.current.emit('enter'));
                element.addEventListener('mouseleave', () => cursorRef.current.emit('leave'));
            });

            document.documentElement.querySelectorAll(`${buttons}, input`).forEach(element => element.addEventListener('mousedown', () => {
                cursorRef.current.emit('enter');
                setTimeout(() => {
                    cursorRef.current.emit('leave');
                }, 100);
            }));
        }

        if (!isMobile) {
            let timer = 0;

            timerRef.current = setInterval(() => {
                timer++;
                setReTriggerMorphingShape();
                if (timer > 1000) {
                    clearInterval(timerRef.current);
                }
            }, 1);
        }

        location.pathname !== '/' && setInitialAnimated(true);
    }, [location.pathname, isMobile]); // eslint-disable-line

    useEffect(() => {
        if (scrollContainer && scrollAxis === 'horizontal') {
            const container = scrollContainer;

            const handler = e => {
                e.preventDefault();
                container.scrollLeft += e.deltaY;
            };

            if (isMobile) {
                container.removeEventListener('wheel', handler);
            } else {
                container.addEventListener('wheel', handler);
            }

            return () => {
                container.removeEventListener('wheel', handler);
            };
        }
    }, [scrollAxis, scrollContainer, isMobile]);

    return (
        <TransitionGroup
            className={classNames(
                'main-layout-holder',
                {
                    'horizontal-scroll': scrollAxis === 'horizontal',
                }
            )}
        >
            <Transition
                onExit={() => setReTriggerMorphingShape()}
                onEnter={() => setReTriggerMorphingShape()}
                onExited={() => setReTriggerMorphingShape()}
                onEntered={() => setReTriggerMorphingShape()}
                key={location.pathname}
                timeout={{
                    exit: timeout,
                    enter: timeout / 2,
                }}
            >
                {status => {
                    const isExit = (status === 'exiting' || status === 'exited');
                    const isMain = children.type.name.toLowerCase() === 'homepage';

                    const goToLeft = getTransitionStyles(false);
                    const goToRight = getTransitionStyles(true);

                    const isLeft = checkDirection('left', directionToGo);
                    const isRight = checkDirection('right', directionToGo);

                    const defaultDirection = goToRight;

                    const selectedDirection = isLeft ? goToLeft : isRight ? goToRight : defaultDirection;
                    const selectedReversedDirection = isLeft ? goToRight : isRight ? goToLeft : defaultDirection;

                    const style = (isExit ? (isMain ? selectedDirection : selectedReversedDirection) : (isMain ? selectedReversedDirection : selectedDirection));

                    return (
                        <div
                            ref={val => setScrollContainer(val)}
                            className={classNames(
                                'main-layout',
                                status,
                            )}
                            onTransitionEnd={() => {
                                setReTriggerMorphingShape();
                                clearInterval(timerRef.current);
                            }}
                            style={style[status]}
                        >
                            {children}
                        </div>
                    );
                }}
            </Transition>
        </TransitionGroup>
    );
};

PageTransition.propTypes = {
    children: PropTypes.any,
    location: PropTypes.object,
};

export default PageTransition;
